import React, { Component } from "react";
import { useRouter } from 'next/router';
import { useSession } from "next-auth/react";
import * as permissions_data from '../config/permissions_data';
import { LoadingComponentFullScreen } from '../src/components/LoadingScreen';
import { getCookies, getCookie, setCookie } from 'cookies-next';
import { useCandidateLinking } from "data/selection_process/candidate";

LandingPage.auth = {
}


export default function LandingPage(props) {
  const { data: session, status } = useSession()
  const router = useRouter()
  const candidateLinking = useCandidateLinking()
  const token_invite_ps = getCookie('token_invite_selection_process')
  deleteCookie('token');
  deleteCookie('token_invite_company');
  deleteCookie('token_invite_selection_process');

  if (token_invite_ps != undefined && candidateLinking.status == 'idle'){
    candidateLinking.mutate(token_invite_ps)
  }

  if (status == "unauthenticated" && candidateLinking != "loading")
    router.push('/auth/login/')

  if (status == "authenticated" && session.user.role == permissions_data.PROGRAMMER_ROLE && candidateLinking != "loading")
    router.push('/home/')

  if (status == "authenticated" && (session.user.role == permissions_data.ADMIN_ROLE || session.user.role == permissions_data.COMPANY_ROLE) && candidateLinking != "loading")
    router.push(`/company/${session.user.tenantId}/selection-processes/`)

  if (status == "authenticated" && session.user.tenantId && (session.user.role == permissions_data.ADMIN_ROLE || session.user.role == permissions_data.COMPANY_ROLE))
    router.push(`/company/create/`)

  return (
    <LoadingComponentFullScreen />
  );
}

function deleteCookie(name) {
  const d = new Date('01 Jan 1970 00:00:01 GMT');
  const expires = "expires=" + d.toUTCString();
  document.cookie = `${name}=${''}; ${expires};path=/`
}